<template>
  <div>

  <v-container>
    <v-row class="d-flex justify-center align-center">
      <v-col class="d-flex justify-start" cols="12" sm="12" md="8" lg="8">
      </v-col>
      <v-col class="d-flex justify-center" cols="12" sm="12" md="8" lg="8">
        <div v-html="content"></div>
      </v-col>
      <v-col v-if="!content" class="d-flex flex-column justify-center align-center" cols="12" sm="12" md="8" lg="8">
        <v-img src="../../assets/Pulse-0.7s-200px.svg" max-width="50"></v-img>
        <h5 class="pa-0">Carregando...</h5> 
       </v-col>
    </v-row>
     <v-row class="d-flex justify-center align-center">
       <v-container class="d-flex justify-center align-center">
         <v-col class="d-flex justify-center pa-0 pt-10 pb-50" cols="12" sm="12" md="8" lg="8">
          <v-btn color="success" class="text-decoration-none" @click="back">Voltar</v-btn>
          </v-col>
        </v-container>
    </v-row>
    
  </v-container>
  <Footer/>
  </div>
</template>

<script>
import newsService from "../../service/newsService";
import covenantsService from "../../service/covenantsService";

export default {
  name: "PostCovenants",
  data() {
    return {
      content: ""
    };
  },
  methods: {
    getPost() {
      const paramsRouter = this.$router.history.current.params
      const id = parseInt(paramsRouter.id)
  
        covenantsService.get({id:id}).then(res => {
          const data = res.data[0];

          this.content = data.content;
        });  

},
    back(){
      this.$router.go(-1)
    }

  },
  components:{
    Footer:()=> import('../../layouts/web/commons/Footer')
  },
  mounted() {
    this.getPost();
  }
};
</script>

<style lang="sass" scoped>
.box-sizing
  box-sizing: border-box
</style>

<style lang="sass">
  .img-post
    max-width: 100%
    background-position: center
    position: relative
    min-width: 100%
    max-width: 100%
    background-position: center
    max-height: 500px
</style>
